<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具></a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">退款订单列表（京训钉）</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 class="flexcb" style="padding-bottom: 20px">
              <span>退款详情</span>
            </h3>
            <div>
              <el-form :inline="false" label-width="10rem" class="formCell">
                <el-form-item label="退款编号：" prop="refundNo">
                  <p>{{ Form.refundNo }}</p>
                </el-form-item>
                 <el-form-item label="退款时间：">
                  <p>{{ Form.createTime | moment }}</p>
                </el-form-item>
                <el-form-item label="退款金额：" prop="price">
                  <p>{{ Form.refundPrice }}</p>
                </el-form-item>
               
                <el-form-item label="退回账户：">
                  <p>
                    {{ Form.payWayName}} 
                  </p>
                </el-form-item>
                <el-form-item label="原因：">
                  <p>{{ Form.reasonTypeName || '--'}}</p>
                </el-form-item>
                <el-form-item label="说明：">
                  <p>{{ Form.refundment || '--'}}</p>
                </el-form-item>
              </el-form>
            </div>
            <!-- <el-divider></el-divider>
            <h3 style="padding-bottom: 20px">买方信息</h3>
             <el-form :inline="false" label-width="10rem" class="formCell">
                <el-form-item label="名称：" prop="orderNo">
                  <p>{{ Form.name }}</p>
                </el-form-item>
                <el-form-item label="负责人：" prop="price">
                  <p>{{ Form.contactName }}</p>
                </el-form-item>
                <el-form-item label="联系电话：">
                  <p>{{ Form.contactMobile }}</p>
                </el-form-item>
              </el-form> -->
            <div>
            </div>
            <el-divider></el-divider>
            <h3 style="padding-bottom: 20px">退款商品信息</h3>
            <el-table
              ref="multipleTable"
              :data="OrderProductList"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="买方"
                align="center"
                :show-overflow-tooltip="true"
                prop="name"
              />
              <el-table-column
                label="订单编号"
                align="center"
                :show-overflow-tooltip="true"
                prop="orderNo"
              />
              <el-table-column
                label="支付时间"
                align="center"
                :show-overflow-tooltip="true"
                prop="orderNo"
              >
              <tempalte slot-scope="scope">
                  {{scope.row.payTime | moment}}
              </tempalte>
              </el-table-column>
              <el-table-column
                label="课程ID"
                align="center"
                :show-overflow-tooltip="true"
                prop="courseId"
              />
              <el-table-column
                label="课程名称"
                align="center"
                :show-overflow-tooltip="true"
                prop="courseName"
              />
              <el-table-column
                label="课程分类"
                align="center"
                :show-overflow-tooltip="true"
                prop="ladderName"
              />
              <el-table-column
                label="单价"
                align="center"
                :show-overflow-tooltip="true"
                prop="price"
              />
              <el-table-column
                label="数量"
                align="center"
                :show-overflow-tooltip="true"
                prop="total"
              />
              <el-table-column
                label="小计(元)"
                align="center"
                :show-overflow-tooltip="true"
                prop="totalPrice"
              />
            </el-table>
       
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "ClassReviewDetails",
  components: {
  },
  mixins: [List],
  data() {
    return {
       id: this.$route.query.id,
       Form:JSON.parse(this.$route.query.getClassInfo).row,
       OrderProductList:[],
    };
  },
  mounted() {
    // this.init();
  },
  created() {
      console.log(this.Form);
      this.getInfo()
  },
  methods: {
      getInfo() {
          this.$post('/biz/ding/refundOrder/productList',{ id: this.id }).then(ret => {
              console.log(ret.data);
              this.OrderProductList = ret.data || []
          })
      },
       goHerf() {
      this.$router.push({
        path: "/web/workOrder/JxdRefundList",
      });
    },
  },
};
</script>
<style lang="less">
.formCom {
  .formCell {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 33%;
    }
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.originalParameter {
  .el-form-item__label {
    color:red
  }
}
</style>
<style lang="less" scoped>
.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}

</style>
